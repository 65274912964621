import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostListener,
  inject,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Provider Expression that allows mlk-button-toggle-group to register as a ControlValueAccessor.
 * This allows it to support [(ngModel)].
 */
export const MLK_TEXT_AREA_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MlkTextAreaGroup),
  multi: true,
};

@Component({
  selector: 'mlk-text-area-group',
  templateUrl: './text-area-group.component.html',
  styleUrls: ['./text-area-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MLK_TEXT_AREA_VALUE_ACCESSOR],
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MlkTextAreaGroup implements ControlValueAccessor {
  private cdr = inject(ChangeDetectorRef);

  @Input() public value = '';

  @Input() public min = '';

  @Input() public max = '';

  @Input() public label = '';

  @Input() public placeholder = '';

  @Input() public id = '';

  @Input() public name = '';

  public disabled = false;

  public writeValue(value: string): void {
    this.value = value;
    this.cdr.markForCheck();
  }

  @HostListener('input', ['$event.target.value']) valueChange(value: string) {
    this._onChange(value);
    this.value = value;
  }

  /** onTouch function registered via registerOnTouch (ControlValueAccessor). */
  public _onTouched!: () => void;

  public _onChange!: (value: string) => void;

  /**  Implemented as part of ControlValueAccessor.*/
  public registerOnChange(fn: (_: string) => void): void {
    this._onChange = fn;
  }

  /** Implemented as part of ControlValueAccessor. */
  public registerOnTouched(fn: () => void) {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
