import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  numberAttribute,
  OnInit,
  Output,
  signal,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MlkIcon } from '../icon';
import { LoopDirective } from 'src/app/common/directives/loop.directive';

let uniqueIdCounter = 0;

/**
 * Provider Expression that allows mlk-button-toggle-group to register as a ControlValueAccessor.
 * This allows it to support [(ngModel)].
 */
export const MLK_STAR_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StarsComponent),
  multi: true,
};

@Component({
  selector: 'mlk-stars',
  imports: [LoopDirective, MlkIcon],
  providers: [MLK_STAR_VALUE_ACCESSOR],
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarsComponent implements OnInit, ControlValueAccessor {
  @Input() public name: string | null = null;

  @Input() public id = '';

  @Input() public label = '';

  @Input({ transform: booleanAttribute }) public disabled = false;

  @Input({ transform: booleanAttribute }) public readonly = false;

  private _max = signal(5);

  private _rate = signal<number>(0);

  @Output() public rateChange = new EventEmitter<string>();

  @HostBinding('style.--stars-size')
  @Input()
  public size = '24px';

  @Input({ transform: numberAttribute, required: false })
  private set rate(nbr: number) {
    this._rate.set(nbr);
  }

  public get rate() {
    return this._rate();
  }

  @Input({ transform: numberAttribute, required: false })
  private set max(nbr: number) {
    this._max.set(nbr);
  }

  public get max() {
    return this._max();
  }

  public writeValue(value: number): void {
    this.rate = value;
  }

  /** onTouch function registered via registerOnTouch (ControlValueAccessor). */
  public _onTouched!: () => void;

  public _onChange!: (value: string) => void;

  /**  Implemented as part of ControlValueAccessor.*/
  public registerOnChange(fn: (_: string) => void): void {
    this._onChange = fn;
  }

  /** Implemented as part of ControlValueAccessor. */
  public registerOnTouched(fn: () => void) {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public ngOnInit(): void {
    this.id = this.id || `stars-${uniqueIdCounter++}`;
  }

  public valueChange(value: string): void {
    this._onChange(value);
  }
}
