import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MlkIconRegistery } from './mlk-icon-registery';
import { take, tap } from 'rxjs';

@Component({
  template: '<ng-content></ng-content>',
  standalone: true,
  selector: 'mlk-icon',
  exportAs: 'mlkIcon',
  inputs: ['color'],
  styleUrls: ['./mlk-icon.component.scss'],
  host: {
    role: 'img',
    class: 'mlk-icon notranslate',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MlkIcon {
  private _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private mlkIconRegistery = inject(MlkIconRegistery);

  @Input() public set svgName(svgName: string) {
    this._clearSvgElement();
    this.mlkIconRegistery
      .getNamedSvgIcon(svgName)
      .pipe(
        take(1),
        tap(svg => this._setSvgElement(svg))
      )
      .subscribe();
  }

  private _clearSvgElement(): void {
    const layoutElement: HTMLElement = this._elementRef.nativeElement;
    let childCount = layoutElement.childNodes.length;

    // Remove existing non-element child nodes and SVGs, and add the new SVG element. Note that
    // we can't use innerHTML, because IE will throw if the element has a data binding.
    while (childCount--) {
      const child = layoutElement.childNodes[childCount];

      // 1 corresponds to Node.ELEMENT_NODE. We remove all non-element nodes in order to get rid
      // of any loose text nodes, as well as any SVG elements in order to remove any old icons.
      if (child.nodeType !== 1 || child.nodeName.toLowerCase() === 'svg') {
        child.remove();
      }
    }
  }

  private _setSvgElement(svg: SVGElement): void {
    this._clearSvgElement();
    this._elementRef.nativeElement.appendChild(svg);
  }
}
