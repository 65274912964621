import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  numberAttribute,
  output,
  QueryList,
} from '@angular/core';

import { MlkTab } from './tab.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'mlk-tabs',
  imports: [NgClass],
  template: `
    <div class="tabs-container">
      <div class="tabs-header">
        <ng-content select="[mlk-tabs-title]"></ng-content>
        <ul class="nav-tabs-items">
          @for (tab of tabs; track tab; let i = $index) {
            <li
              class="tab-item"
              [ngClass]="tab.selected() === true ? 'mlk-badge-blue' : ''"
              (click)="selectTab(tab, i)">
              {{ tab.label }}
            </li>
          }
        </ul>
      </div>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./tabs.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MlkTabs implements AfterContentInit {
  @ContentChildren(MlkTab) public tabs!: QueryList<MlkTab>;

  @Input({ transform: numberAttribute }) public selectedIndex = 0;

  readonly indexChange = output<number>();

  ngAfterContentInit(): void {
    const index = this.selectedIndex;

    this.selectTab(this.tabs.toArray()[index], index);
  }

  selectTab(tab: MlkTab, index: number): void {
    this.tabs.toArray().forEach(tab => tab.selected.set(false));
    tab.selected.set(true);
    this.indexChange.emit(index);
  }
}
