import {
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  input,
  OnDestroy,
  output,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { SliderCustomImageComponent } from './slider-custom-image/slider-image.component';
import { NgClass, NgStyle } from '@angular/common';

const SPEED = 1;

@Component({
  selector: 'mlk-image-fullscreen-view',
  templateUrl: './image-fullscreen-view.html',
  styleUrls: ['./image-fullscreen-view.scss'],
  imports: [SliderCustomImageComponent, NgClass, NgStyle],
  encapsulation: ViewEncapsulation.None,
})
export class NgImageFullscreenViewComponent implements OnDestroy {
  private readonly elRef = inject(ElementRef);

  nextImageIndex = -1;
  popupWidth = 1200;
  marginLeft = 0;
  imageFullscreenView = signal(false);
  lightboxPrevDisable = signal(false);
  lightboxNextDisable = signal(false);
  showLoading = signal(true);
  effectStyle = 'none';

  currentImageIndex = signal(0);

  // for swipe event
  private swipeLightboxImgCoord = [0, 0];
  private swipeLightboxImgTime = 0;

  // @Inputs
  @Input() images: string[] = [];

  title = input('');

  @Input()
  set imageIndex(index: number) {
    if (index !== undefined && index > -1 && index < this.images.length) {
      this.currentImageIndex.set(index);
    }
    this.nextPrevDisable();
  }

  @Input()
  set show(visiableFlag: boolean) {
    this.imageFullscreenView.set(visiableFlag);
    this.elRef.nativeElement.ownerDocument.body.style.overflow = '';
    if (visiableFlag === true) {
      this.elRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
      this.setPopupSliderWidth();
    }
  }

  readonly paginationShow = input(false);

  readonly infinite = input(false);

  readonly arrowKeyMove = input(true);

  readonly closeViewer = output<void>();
  readonly prevImage = output<void>();
  readonly nextImage = output<void>();

  @HostListener('window:resize')
  onResize() {
    this.effectStyle = 'none';
    this.setPopupSliderWidth();
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event && event.key && this.arrowKeyMove()) {
      if (event.key.toLowerCase() === 'arrowright') {
        this.nextImageLightbox();
      }

      if (event.key.toLowerCase() === 'arrowleft') {
        this.prevImageLightbox();
      }

      if (event.key.toLowerCase() === 'escape') {
        this.closeLightbox();
      }
    }
  }

  ngOnDestroy() {
    this.resetState();
  }

  setPopupSliderWidth() {
    if (window && window.innerWidth) {
      this.popupWidth = window.innerWidth;

      this.marginLeft = -1 * this.popupWidth * this.currentImageIndex();
      this.nextPrevDisable();
      setTimeout(() => {
        this.showLoading.set(false);
      }, 500);
    }
  }

  closeLightbox() {
    this.closeViewer.emit();
  }

  prevImageLightbox() {
    this.effectStyle = `all ${SPEED}s ease-in-out`;
    if (this.currentImageIndex() > 0 && !this.lightboxPrevDisable()) {
      this.currentImageIndex.update(i => --i);
      this.prevImage.emit();
      this.marginLeft = -1 * this.popupWidth * this.currentImageIndex();
      this.nextPrevDisable();
    }
  }

  nextImageLightbox() {
    this.effectStyle = `all ${SPEED}s ease-in-out`;
    if (
      this.currentImageIndex() < this.images.length - 1 &&
      !this.lightboxNextDisable()
    ) {
      this.currentImageIndex.update(i => ++i);
    } else {
      this.currentImageIndex.update(i => 0);
    }
    this.nextImage.emit();
    this.marginLeft = -1 * this.popupWidth * this.currentImageIndex();
    this.nextPrevDisable();
  }

  nextPrevDisable() {
    this.lightboxNextDisable.set(true);
    this.lightboxPrevDisable.set(true);
    setTimeout(() => {
      this.applyButtonDisableCondition();
    }, SPEED * 1000);
  }

  applyButtonDisableCondition() {
    this.lightboxNextDisable.set(false);
    this.lightboxPrevDisable.set(false);

    if (
      !this.infinite() &&
      this.currentImageIndex() >= this.images.length - 1
    ) {
      this.lightboxNextDisable.set(true);
    }
    if (!this.infinite() && this.currentImageIndex() <= 0) {
      this.lightboxPrevDisable.set(true);
    }
  }

  resetState(): void {
    this.images.splice(0, this.images.length);
  }

  /**
   * Swipe event handler
   * Reference from https://stackoverflow.com/a/44511007/2067646
   */
  swipeLightboxImg(e: TouchEvent, when: string): void {
    const coord = [e.changedTouches[0].pageX, e.changedTouches[0].pageY] as [
      number,
      number,
    ];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeLightboxImgCoord = coord;
      this.swipeLightboxImgTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeLightboxImgCoord[0],
        coord[1] - this.swipeLightboxImgCoord[1],
      ];
      const duration = time - this.swipeLightboxImgTime;

      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        // Horizontal enough
        if (direction[0] < 0) {
          this.nextImageLightbox();
        } else {
          this.prevImageLightbox();
        }
      }
    }
  }
}
