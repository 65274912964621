import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  forwardRef,
  InjectionToken,
  Input,
  OnInit,
  output,
  QueryList,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MlkButtonToggle } from './button-toggle.component';
import { SelectionModel } from '@angular/cdk/collections';

let uniqueIdCounter = 0;

/**
 * Provider Expression that allows mlk-button-toggle-group to register as a ControlValueAccessor.
 * This allows it to support [(ngModel)].
 */
export const MLK_BUTTON_TOGGLE_GROUP_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MlkButtonToggleGroup),
  multi: true,
};

/**
 * Injection token that can be used to reference instances of `MlkButtonToggleGroup`.
 * It serves as alternative token to the actual `MlkButtonToggleGroup` class which
 * could cause unnecessary retention of the class and its component metadata.
 */
export const MLK_BUTTON_TOGGLE_GROUP = new InjectionToken<MlkButtonToggleGroup>(
  'MlkButtonToggleGroup'
);

/** Change event object emitted by button toggle. */
export class MlkButtonToggleChange {
  constructor(
    /** The button toggle that emits the event. */
    public source: MlkButtonToggle,

    /** The value assigned to the button toggle. */
    public value: string | undefined
  ) {}
}

@Directive({
  selector: 'mlk-button-group',
  exportAs: 'mlkButtonToggleGroup',
  providers: [
    MLK_BUTTON_TOGGLE_GROUP_VALUE_ACCESSOR,
    { provide: MLK_BUTTON_TOGGLE_GROUP, useExisting: MlkButtonToggleGroup },
  ],
  standalone: true,
})
export class MlkButtonToggleGroup
  implements OnInit, AfterContentInit, ControlValueAccessor
{
  private _disabled = false;
  private _selectionModel!: SelectionModel<MlkButtonToggle>;

  /**
   * Reference to the raw value that the consumer tried to assign. The real
   * value will exclude any values from this one that don't correspond to a
   * toggle. Useful for the cases where the value is assigned before the toggles
   * have been initialized or at the same that they're being swapped out.
   */
  private _rawValue: string | undefined = undefined;

  /**
   * The method to be called in order to update ngModel.
   * Now `ngModel` binding is not supported in multiple selection mode.
   */
  _controlValueAccessorChangeFn: (value: string | undefined) => void = () => {};

  /** onTouch function registered via registerOnTouch (ControlValueAccessor). */
  _onTouched: () => void = () => {};

  /** Child button toggle buttons. */
  @ContentChildren(forwardRef(() => MlkButtonToggle), {
    // Note that this would technically pick up toggles
    // from nested groups, but that's not a case that we support.
    descendants: true,
  })
  _buttonToggles!: QueryList<MlkButtonToggle>;

  /** `name` attribute for the underlying `input` element. */
  @Input()
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
    this._markButtonsForCheck();
  }
  private _name = `mlk-button-toggle-group-${uniqueIdCounter++}`;

  /** Value of the toggle group. */
  @Input()
  get value(): string | undefined {
    const selected = this._selectionModel ? this._selectionModel.selected : [];

    return selected[0] ? selected[0].value : undefined;
  }
  set value(newValue: string) {
    this._setSelectionByValue(newValue);
    this.valueChange.emit(this.value);
  }

  /**
   * Event that emits whenever the value of the group changes.
   * Used to facilitate two-way data binding.
   * @docs-private
   */
  readonly valueChange = output<any>();

  /** Selected button toggles in the group. */
  get selected(): MlkButtonToggle | MlkButtonToggle[] {
    const selected = this._selectionModel ? this._selectionModel.selected : [];
    return selected[0] || null;
  }

  /** Whether multiple button toggle group is disabled. */
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
    this._markButtonsForCheck();
  }

  /** Event emitted when the group's value changes. */
  readonly change = output<MlkButtonToggleChange>();

  constructor(private _changeDetector: ChangeDetectorRef) {}

  public ngOnInit() {
    this._selectionModel = new SelectionModel<MlkButtonToggle>(
      false,
      undefined,
      false
    );
  }

  public ngAfterContentInit() {
    this._selectionModel.select(
      ...this._buttonToggles.filter(toggle => toggle.checked)
    );
  }

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   * @param value Value to be set to the model.
   */
  public writeValue(value: string) {
    this.value = value;
    this._changeDetector.markForCheck();
  }

  /** Implemented as part of ControlValueAccessor. */
  public registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  /** Implemented as part of ControlValueAccessor.*/
  public registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  /** Implemented as part of ControlValueAccessor.*/
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** Dispatch change event with current selection and group value. */
  public _emitChangeEvent(toggle: MlkButtonToggle): void {
    const event = new MlkButtonToggleChange(toggle, this.value);
    this._rawValue = event.value;
    this._controlValueAccessorChangeFn(event.value);
    this.change.emit(event);
  }

  /**
   * Syncs a button toggle's selected state with the model value.
   * @param toggle Toggle to be synced.
   * @param select Whether the toggle should be selected.
   * @param isUserInput Whether the change was a result of a user interaction.
   * @param deferEvents Whether to defer emitting the change events.
   */
  public _syncButtonToggle(
    toggle: MlkButtonToggle,
    select: boolean,
    isUserInput = false,
    deferEvents = false
  ) {
    // Deselect the currently-selected toggle, if we're in single-selection
    // mode and the button being toggled isn't selected at the moment.
    if (this.selected && !toggle.checked) {
      (this.selected as MlkButtonToggle).checked = false;
    }

    if (this._selectionModel) {
      if (select) {
        this._selectionModel.select(toggle);
      } else {
        this._selectionModel.deselect(toggle);
      }
    } else {
      deferEvents = true;
    }

    // We need to defer in some cases in order to avoid "changed after checked errors", however
    // the side-effect is that we may end up updating the model value out of sequence in others
    // The `deferEvents` flag allows us to decide whether to do it on a case-by-case basis.
    if (deferEvents) {
      Promise.resolve().then(() => this._updateModelValue(toggle, isUserInput));
    } else {
      this._updateModelValue(toggle, isUserInput);
    }
  }

  /** Checks whether a button toggle is selected. */
  public _isSelected(toggle: MlkButtonToggle) {
    return this._selectionModel && this._selectionModel.isSelected(toggle);
  }

  /** Determines whether a button toggle should be checked on init. */
  public _isPrechecked(toggle: MlkButtonToggle) {
    if (typeof this._rawValue === 'undefined') {
      return false;
    }

    return toggle.value === this._rawValue;
  }

  /** Updates the selection state of the toggles in the group based on a value. */
  private _setSelectionByValue(value: string) {
    this._rawValue = value;

    if (!this._buttonToggles) {
      return;
    }

    this._clearSelection();
    this._selectValue(value);
  }

  /** Clears the selected toggles. */
  private _clearSelection() {
    this._selectionModel.clear();
    this._buttonToggles.forEach(toggle => (toggle.checked = false));
  }

  /** Selects a value if there's a toggle that corresponds to it. */
  private _selectValue(value: string) {
    const correspondingOption = this._buttonToggles.find(toggle => {
      return toggle.value != null && toggle.value === value;
    });

    if (correspondingOption) {
      correspondingOption.checked = true;
      this._selectionModel.select(correspondingOption);
    }
  }

  /** Syncs up the group's value with the model and emits the change event. */
  private _updateModelValue(toggle: MlkButtonToggle, isUserInput: boolean) {
    // Only emit the change event for user input.
    if (isUserInput) {
      this._emitChangeEvent(toggle);
    }

    // Note: we emit this one no matter whether it was a user interaction, because
    // it is used by Angular to sync up the two-way data binding.
    this.valueChange.emit(this.value);
  }

  /** Marks all of the child button toggles to be checked. */
  private _markButtonsForCheck() {
    this._buttonToggles?.forEach(toggle => toggle._markForCheck());
  }
}
