import { NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ProfilPicturePipe } from '@common/pipes';

@Component({
  selector: 'mlk-profil-picture',
  imports: [NgOptimizedImage, ProfilPicturePipe],
  templateUrl: './profil-picture.component.html',
  styleUrl: './profil-picture.component.scss',
})
export class ProfilPictureComponent {
  @Input({ required: true }) profil: Record<string, string> = {};
}
