import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  OnInit,
  output,
  ViewChild,
} from '@angular/core';
import {
  MLK_BUTTON_TOGGLE_GROUP,
  MlkButtonToggleChange,
  MlkButtonToggleGroup,
} from './button-toggle-group.directive';

// Counter used to generate unique IDs.
let uniqueIdCounter = 0;

@Component({
  selector: 'mlk-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MlkButtonToggle implements OnInit, OnDestroy {
  private toggleGroup = inject(MLK_BUTTON_TOGGLE_GROUP);
  private _changeDetectorRef = inject(ChangeDetectorRef);
  private _checked = false;

  /**
   * Attached to the aria-label attribute of the host element. In most cases, aria-labelledby will
   * take precedence so this may be omitted.
   */
  @Input('aria-label') ariaLabel: string | null = null;

  /**
   * Users can specify the `aria-labelledby` attribute which will be forwarded to the input element
   */
  @Input('aria-labelledby') ariaLabelledby: string | null = null;

  /** Underlying native `button` element. */
  @ViewChild('button') _buttonElement!: ElementRef<HTMLButtonElement>;

  /** The parent button toggle group (exclusive selection). Optional. */
  buttonToggleGroup: MlkButtonToggleGroup;

  /** Unique ID for the underlying `button` element. */
  get buttonId(): string {
    return `${this.id}-button`;
  }

  /** The unique ID for this button toggle. */
  @Input() public id = '';

  /** HTML's 'name' attribute used to group radios for unique selection. */
  @Input() public name = '';

  /** MatButtonToggleGroup reads this to assign its own value. */
  @Input({ required: true }) value = '';

  /** Whether the button is checked. */
  @Input({ transform: booleanAttribute })
  get checked(): boolean {
    return this.buttonToggleGroup
      ? this.buttonToggleGroup._isSelected(this)
      : this._checked;
  }
  set checked(value: boolean) {
    if (value !== this._checked) {
      this._checked = value;

      if (this.buttonToggleGroup) {
        this.buttonToggleGroup._syncButtonToggle(this, this._checked);
      }

      this._changeDetectorRef.markForCheck();
    }
  }

  @HostBinding('attr.checked')
  get checkedAttr(): '' | null {
    return this.checked ? '' : null;
  }

  /** Whether the button is disabled. */
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return (
      this._disabled ||
      (this.buttonToggleGroup && this.buttonToggleGroup.disabled)
    );
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }
  private _disabled = false;

  /** Event emitted when the group value changes. */
  readonly change = output<MlkButtonToggleChange>();

  constructor() {
    this.buttonToggleGroup = this.toggleGroup;
  }

  ngOnInit() {
    const group = this.buttonToggleGroup;
    this.id = this.id || `mlk-button-toggle-${uniqueIdCounter++}`;

    if (group) {
      if (group._isPrechecked(this)) {
        this.checked = true;
      } else if (group._isSelected(this) !== this._checked) {
        // As as side effect of the circular dependency between the toggle group and the button,
        // we may end up in a state where the button is supposed to be checked on init, but it
        // isn't, because the checked value was assigned too early. This can happen when Ivy
        // assigns the static input value before the `ngOnInit` has run.
        group._syncButtonToggle(this, this._checked);
      }
    }
  }

  public ngOnDestroy() {
    const group = this.buttonToggleGroup;

    // Remove the toggle from the selection once it's destroyed. Needs to happen
    // on the next tick in order to avoid "changed after checked" errors.
    if (group && group._isSelected(this)) {
      group._syncButtonToggle(this, false, false, true);
    }
  }

  /** Focuses the button. */
  focus(options?: FocusOptions): void {
    this._buttonElement.nativeElement.focus(options);
  }

  /** Checks the button toggle due to an interaction with the underlying native button. */
  _onButtonClick() {
    if (!this._checked) {
      this._checked = !this._checked;
      if (this.buttonToggleGroup) {
        this.buttonToggleGroup._syncButtonToggle(this, this._checked, true);
        this.buttonToggleGroup._onTouched();
      }
    }
    // Emit a change event when it's the single selector
    this.change.emit(new MlkButtonToggleChange(this, this.value));
  }

  /**
   * Marks the button toggle as needing checking for change detection.
   * This method is exposed because the parent button toggle group will directly
   * update bound properties of the radio button.
   */
  _markForCheck() {
    // When the group value changes, the button will not be notified.
    // Use `markForCheck` to explicit update button toggle's status.
    this._changeDetectorRef.markForCheck();
  }

  /** Gets the name that should be assigned to the inner DOM node. */
  _getButtonName(): string | null {
    return this.buttonToggleGroup.name;
  }
}
